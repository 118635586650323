/* eslint-disable react/self-closing-comp */
/* eslint-disable react/prop-types */
import React from 'react';
import { graphql } from 'gatsby';
import { Accordion, createStyles } from '@mantine/core';
import { PlusIcon } from '@modulz/radix-icons';

import PageHeader from '../components/PageHeader';
import Content from '../components/Content.js';
import Layout from '../components/Layout.js';
// import Gallery from '../components/Gallery';

const useStyles = createStyles((theme, _params, getRef) => {
  const controlRef = getRef('control');
  const iconRef = getRef('icon');

  return {
    icon: { ref: iconRef },

    control: {
      ref: controlRef,
      border: 0,
      opacity: 0.6,
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      backgroundColor: '#97977c',
      '&:hover': {
        backgroundColor: '#87977c',
        opacity: 1
      },
      marginBottom: 16
    },

    item: {
      borderBottom: 0,
      overflow: 'hidden',
      transition: `box-shadow 150ms ${theme.transitionTimingFunction}`,
      border: '1px solid transparent',
      borderRadius: theme.radius.sm,
      marginBottom: 16
    },
    label: {
      fontSize: 24
    },
    itemOpened: {
      backgroundColor: '#f1e1cc',
      borderColor: '#c7b6a1',

      [`& .${controlRef}`]: {
        opacity: 1
      },

      [`& .${iconRef}`]: {
        transform: 'rotate(45deg)'
      }
    },

    content: {
      paddingLeft: 0
    }
  };
});

function StyledAccordion(props) {
  const { classes } = useStyles();
  return <Accordion classNames={classes} icon={<PlusIcon />} {...props} />;
}

// Export Template for use in CMS preview
const ServicePageTemplate = ({ Product_Category }) => {
  const {
    Title,
    Featured_Image,
    Description,
    featureImageOffsetX,
    featureImageOffsetY
  } = Product_Category[0];
  const accordians = Product_Category[0].Products_Sub_Categories.map((s, i) => {
    return (
      <Accordion.Item key={`accordian_${i}`} label={s.Title}>
        <Content source={s.description} />
      </Accordion.Item>
    );
  });
  return (
    <main>
      <PageHeader
        large
        title={Title}
        imageOffsetX={featureImageOffsetX}
        imageOffsetY={featureImageOffsetY}
        backgroundImage={Featured_Image}
      />

      <section className="section">
        <div className="container">
          <Content source={Description} />
        </div>
        <div className="container">
          <StyledAccordion>{accordians}</StyledAccordion>
        </div>
      </section>
    </main>
  );
};

const ServicePage = ({ data }) => {
  const { directus } = data;
  return (
    <Layout meta={false} title={false}>
      <ServicePageTemplate {...directus} />
    </Layout>
  );
};

export const query = graphql`
  query ($slug: String!) {
    directus {
      Product_Category(filter: { Unique_Name: { _eq: $slug } }) {
        Title
        Description
        featureImageOffsetX
        featureImageOffsetY
        Featured_Image {
          id
        }
        Products_Sub_Categories(sort: "Display_Priority") {
          Title
          description
        }
      }
    }
  }
`;

export default ServicePage;
